<template>
  <div class="header-recommendation">
    <div class="top-section">
      <div class="container-fluid">
        <div class="row">
          <div class="top-main">
            <h5 class="beauty-title">YOUR <br />BEAUTY PROFILE</h5>

            <ul class="top-section-product">
              <li v-for="(st, index) in userSkintypes" :key="index">
                <a href="" @click.prevent>{{ st.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar-section">
      <div class="container-fluid">
        <div class="row">
          <div class="navbar-header">
            <div class="promo-logo">
              <h1><router-link :to="`/recommendation/promotion`">PROMO</router-link></h1>
            </div>
            <div class="home-top-search hidden">
              <a @click="search = true"> <img src="/static/img/icons/search.svg" alt="SOCO" />SEARCH </a>
              <div v-show="search" class="search">
                <input
                  v-model="keyword"
                  type="text"
                  name="search"
                  placeholder="Search..."
                  autocomplete="off"
                  class="input-search"
                  @keyup.enter="searching"
                  @keyup="getGlobalSearch($event)"
                  @focus="triggerShowSearchPopup()"
                  @blur="triggerHideSearchPopup()"
                />
                <span class="icon-search"></span>
                <div v-if="showSearchPopup" class="search-result">
                  <ul v-if="globalSearch.length">
                    <li v-for="product in globalSearch" :key="product.id">
                      <template v-if="product.la_ps_product_id_product">
                        <a
                          :href="
                            '/recommendation/product-details?sociolla_id=' +
                            product.la_ps_product_id_product +
                            '&soco_id=' +
                            product.id
                          "
                        >
                          <div class="card card-inline">
                            <div class="card-header">
                              <figure>
                                <img :src="product.product_images[0].url" alt="SOCO" />
                                <!-- <img src="/static/img/icons/dummy.svg" alt="SOCO"> -->
                              </figure>
                            </div>
                            <div class="card-body">
                              <h4>{{ product.manufacturer_name || product.manufacture.name }}</h4>
                              <p>{{ product.product_name || product.name }}</p>
                              <div class="rate">
                                <div class="rs rs-44" :class="'rs rs-44-' + $options.filters.rating(product.rating)">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <div class="count">({{ product.product_stock }})</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </template>

                      <template v-else>
                        <a
                          :href="
                            '/recommendation/product-details?sociolla_id=' +
                            product.la_ps_product_id_product +
                            '&soco_id=' +
                            product.id
                          "
                        >
                          <div class="card card-inline">
                            <div class="card-header">
                              <figure>
                                <img :src="product.product_images[0].url" alt="SOCO" />
                                <!-- <img src="/static/img/icons/dummy.svg" alt="SOCO"> -->
                              </figure>
                            </div>
                            <div class="card-body">
                              <h4>{{ product.manufacture.name }}</h4>
                              <p>{{ product.name }}</p>
                              <div class="rate">
                                <div class="rs rs-44">
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <span></span>
                                  <div class="count">(30)</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      </template>
                    </li>
                  </ul>
                  <div v-else id="loaderspinwrap" class="loaderspinwrap"><div class="loaderspin"></div></div>

                  <template v-for="(brand, key) in globalSearch">
                    <a :key="key" href="#" class="brand_logo_item">
                      <!-- {{brand.name}} -->
                      <!-- <template v-if="brand.logo">
															<img :src="brand.logo" alt="SOCO" />
													</template>
													<template v-else>
															<img src="/static/img/default-brand-img.svg" alt="SOCO" />
													</template> -->
                    </a>
                  </template>
                </div>
              </div>
            </div>
            <div v-if="$store.state.QRcode.isLoggedIn" class="user-menu">
              <div class="user-img">
                <img v-if="auth.userProfile" :src="auth.userProfile.image" @error="imageError($event)" />
                <img v-else src="/static/img/avatar-soco.png" alt="SOCO" />
              </div>
              <div class="userlog">
                <p>
                  <a id="show-modal" @click.prevent="logoutQRSession()">LOG OUT</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <div class="loader">
              <!-- <img src="/static/img/icons/loading.svg" alt="SOCO" /> -->
              <transition name="loader">
                <div class="loaderspinwrap">
                  <div class="loaderspin"></div>
                </div>
              </transition>
            </div>
            <h4>Logging Out</h4>
            <p>Terima kasih sudah menggunakan</p>
            <p>SOCO Interactive Recommendation Machine</p>
          </div>
        </div>
      </div>
      <div class="overlay"></div>
    </div>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside';

let CancelToken;
let source;

export default {
  name: 'headerRecommendation',
  directives: {
    ClickOutside,
  },
  data() {
    return {
      showSearchPopup: false,
      globalSearch: [],
      keyword: '',
      search: false,
    };
  },
  computed: {
    userSkintypes() {
      return this.$store.state.auth.userSkintypes;
    },
    showModal() {
      return this.$store.state.QRcode.isLoggingOut;
    },
    auth() {
      return this.$store.state.auth;
    },
  },
  created() {
    // this.getGlobalSearch()
    if (!this.$store.state.QRcode.isLoggedIn) {
      this.$store.dispatch('me').then((response) => {
        if (!response) {
          this.$router.push('/recommendation/splash');
        }
      });
    }
  },
  methods: {
    triggerHideSearchPopup() {
      const thisel = this;
      setTimeout(function () {
        thisel.showSearchPopup = false;
      }, 500);
    },
    triggerShowSearchPopup() {
      this.showSearchPopup = this.keyword.length > 3 ? true : false;
    },
    getGlobalSearch(e) {
      if (e.code !== 'Enter') {
        if (this.keyword.length > 3) {
          this.showSearchPopup = true;
          if (typeof source !== 'undefined') {
            source.cancel(false);
          }
          CancelToken = this.$MS_SOCO_PUBLIC_API_URL.CancelToken;
          source = CancelToken.source();

          this.$MS_SOCO_PUBLIC_API_URL
            .get(`/global-search`, {
              params: {
                filter: {
                  keyword: this.keyword,
                  types: ['add-review'],
                  availability: true,
                  limit: 3,
                },
              },
              cancelToken: source.token,
            })
            .then((res) => {
              this.globalSearch = res.data.data['add-review'];
            });
        } else {
          this.showSearchPopup = false;
          if (typeof source !== 'undefined') {
            source.cancel(false);
          }
        }
      } else {
        if (typeof source !== 'undefined') {
          source.cancel(false);
        }
        this.showSearchPopup = false;
        this.$router.push({ path: '/recommendation/search-product', query: { q: this.keyword } });
      }
    },
    searching() {
      this.showSearchPopup = false;
    },
    logoutQRSession() {
      this.$store.dispatch('destroyQrSession', {
        router: this.$router,
        redirectUrl: '/recommendation/splash',
      });
    },
    imageError(e) {
      e.target.src = '~static/img/avatar-soco.png';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'header';

body {
  background: #f8f8f8;
}

.loaderspin {
  border: 7px solid $pinkalt2;
  border-top: 7px solid $pinkdarkers;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0px auto 0;
  &.loader-leave-to {
    display: none;
  }
  &.withtext {
    margin-top: 48px;
    margin-bottom: 24px;
  }
}
.loaderspinwrap {
  text-align: center;
  width: 100%;
  p {
    color: $grey;
  }
}
</style>
